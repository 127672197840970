import axios, { AxiosPromise, AxiosRequestHeaders } from 'axios';
import qs from 'qs';
import actualSlider from '../data/index/actual.json';
import videoSlider from '../data/index/video.json';
import servicesBlock from '../data/index/services.json';
import tabsHeader from '../data/blocks/tabs.json';
import tagsHeader from '../data/blocks/tags.json';
import leadImg from '../data/blocks/leadImg.json';
import leadText from '../data/blocks/leadText.json';
import bannerText from '../data/blocks/bannerText.json';
import procedure from '../data/blocks/cardProcedure.json';
import sliderEducationReviews from '../data/blocks/sliderEducationReviews.json';
import purchasesSlider from '../data/blocks/purchasesSlider.json';
import documents from '../data/blocks/documents.json';
import bannerSlider from '../data/blocks/bannerSlider.json';
import contentSpoiler from '../data/blocks/contentSpoiler.json';
import contentSpoilerInner from '../data/blocks/contentSpoilerInner.json';
import wrapperSpoiler from '../data/blocks/wrapperSpoiler.json';
import contacts from '../data/blocks/contacts.json';
import actualSliderInner from '../data/blocks/actualSliderInner.json';
import customersSliderInner from '../data/blocks/customersSliderInner.json';
import advertisementBanners from '../data/blocks/advertisementBanners.json';
import educationInner from '../data/blocks/educationInner.json';
import services from '../data/blocks/services.json';
import cardInfoOrganization from '../data/blocks/cardInfoOrganization.json';
import news from '../data/blocks/news.json';
import arcticle from '../data/blocks/arcticle.json';
import articleSupportNavigator from '@/data/blocks/articleSupportNavigator.json';
import infoList from '../data/blocks/infoList.json';
import infoBlockWithIcons from '../data/blocks/infoBlockWithIcons.json';
import list from '../data/blocks/list.json';
import infoCards from '../data/blocks/infoCards.json';
import videoSliderInner from '../data/blocks/videoSliderInner.json';
import categoryCards from '../data/blocks/categoryCards.json';
import textWithImage from '../data/blocks/textWithImage.json';
import cardEducationEvents from '../data/blocks/cardEducationEvents.json';
import educationCourses from '../data/blocks/educationCourses.json';
import playlist from '../data/blocks/playlist.json';
import tariffGrid from '../data/blocks/tariffGrid.json';
import cardSet from '../data/blocks/cardSet.json';
import educationVideo from '../data/blocks/educationVideo.json';
import indicatorsCardsInner from '../data/blocks/indicatorsCardsInner.json';
import subjectCards from '../data/blocks/subjectCards.json';
import serviceBenefits from '../data/blocks/serviceBenefits.json';
import proceduresList from '../data/blocks/proceduresList.json';
import socialNetworks from '../data/blocks/socialNetworks.json';
import stepsStatic from '../data/blocks/stepsStatic.json';
import pictureBlock from '../data/blocks/pictureBlock.json';
import barChartsPercents from '../data/blocks/barChartsPercents.json';
import serviceTariffSlider from '../data/blocks/serviceTariffSlider.json';
import benefitsCards from '../data/blocks/benefitsCards.json';
import numberedList from '../data/blocks/numberedList.json';
import interRao from '../data/documents/interRao.json';
import stepsTabs from '../data/blocks/stepsTabs.json';
import actualCards from '../data/blocks/actualCards.json';
import program from '../data/blocks/program.json';
import tablePartners from '../data/blocks/tablePartners.json';
import tableQualification from '../data/blocks/tableQualification.json';
import tableCardsProcedure from '../data/blocks/tableCardsProcedure.json';
import tableCriteriasRequirements from '../data/blocks/tableCriteriasRequirements.json';
import tableProcedureUnits from '../data/blocks/tableProcedureUnits.json';
import tablePaymentPlan from '../data/blocks/tablePaymentPlan.json';
import tableProcedureCriteriaIndicators from '../data/blocks/tableProcedureCriteriaIndicators.json';
import vacanciesSpoilers from '../data/blocks/vacanciesSpoilers.json';
import procedureGeneralInfo from '../data/blocks/procedureGeneralInfo.json';
import lotsRosneft from '../data/blocks/lotsRosneft.json';
import procedureGeneralInfoWithExtra from '../data/blocks/procedureGeneralInfoWithExtra.json';
import showMoreText from '../data/blocks/showMoreText.json';
import pictureGraph from '../data/blocks/pictureGraph.json';
import interRAO from '../data/pages/InterRAO.json';
import rosneft from '../data/pages/rosneft.json';
import cardProcedureCatalog from '@/data/blocks/cardProcedureCatalog.json';
import docsSection from '../data/blocks/docsSection.json';
import procedureGeneralInfoWithExtra44fz from '../data/blocks/procedureGeneralInfoWithExtra44fz.json';
import govProcurement from '../data/pages/govProcurement.json';
import regions from '../data/regions.json';
import tradeSection from '../data/tradeSections.json';

import {
    OrchidPromise,
    IProcedureShort,
    INewsCard,
    IVacancyHh,
    IOrganizationListing,
    IDictionary,
    IQualification,
    ICriterias,
    IPartners,
    IProcedureView,
    IVacanciesHh,
    IQualificationRequirements,
    ISupportSections,
    ITariffSection,
    INews,
    IYTPlaylist,
    IYTTag,
    IEducationPost,
    IVolumeIncrease,
    IEducationEvent,
    IOurCustomerResponseData,
    IOurCustomerProceduresResponseData,
    TLocale,
    IReview,
    IClientLettersData,
    ILotUnit,
    IIndustryCategories
} from '@/interfaces';

export interface BasicRequestOptions<P = Record<string, string | string[] | boolean>> {
    params?: Partial<P>;
    headers?: AxiosRequestHeaders;
}

const API_EVS_TIMEOUT = Number(process.env.API_EVS_TIMEOUT) || 0;

function newAbortSignal(timeoutMs: number) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || API_EVS_TIMEOUT || 10000);

    return abortController.signal;
}

/** @description - Адрес /api для запросов на бэк */

const createAxiosInstance = (baseURL: string | undefined) =>
    axios.create({
        baseURL: baseURL,
        headers: {
            Accept: 'application/json'
        },
        maxBodyLength: Infinity,
        maxContentLength: Infinity
    });

const axiosOpenApiInstance = axios.create({
    baseURL: process.env.API_URL_OPENAPI,
    headers: {
        Accept: 'application/json',
        Host: process.env.API_HOST_OPENAPI ?? ''
    },
    maxBodyLength: Infinity,
    maxContentLength: Infinity
});

const axiosApiHh = createAxiosInstance(process.env.API_URL_HH);

export const contentApiInstance = axios.create({
    baseURL: process.env.API_URL_CONTENT,
    headers: {
        Authorization: process.env.API_AUTH ?? '',
        'Cache-Control': 'no-store'
    },
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    withCredentials: true
});

const contentApiPrivateInstance = axios.create({
    baseURL: process.env.API_URL_CONTENT_PRIVATE,
    headers: {
        Authorization: process.env.API_AUTH ?? '',
        'Cache-Control': 'no-store'
    },
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    withCredentials: true
});

// blocks
export const getActualSlider = () => actualSlider;

export const getVideoSlider = () => videoSlider;

export const getServicesBlock = () => servicesBlock;

export const getBannerText = () => bannerText;

export const getLeadImg = () => leadImg;

export const getLeadText = () => leadText;

export const getTabsHeader = () => tabsHeader;

export const getTagsHeader = () => tagsHeader;

export const getProcedure = () => procedure;

export const getDocuments = () => documents;

export const getSliderEducationReviews = () => sliderEducationReviews;

export const getBannerSlider = () => bannerSlider;

export const getPurchases = () => purchasesSlider;

export const getContentSpoiler = () => contentSpoiler;

export const getContentSpoilerInner = () => contentSpoilerInner;

export const getWrapperSpoiler = () => wrapperSpoiler;

export const getAdvertisementBanners = () => advertisementBanners;

export const getActualSliderInner = () => actualSliderInner;

export const getCustomersSliderInner = () => customersSliderInner;

export const getCardInfoOrganization = () => cardInfoOrganization;

export const getServicesCardsInner = () => services;

export const getInfoList = () => infoList;

export const getEducationInner = () => educationInner;

export const getInfoCards = () => infoCards;

export const getInfoBlockWithIcons = () => infoBlockWithIcons;

export const getContacts = () => contacts;

export const getNewsItemMock = () => news;

export const getNewsItemArcticle = () => arcticle;

export const getArticleSupportNavigator = () => articleSupportNavigator;

export const getCategoryCards = () => categoryCards;

export const getVideoSliderInner = () => videoSliderInner;

export const getPlaylist = () => playlist;

export const getTextWithImage = () => textWithImage;

export const getCardEducationEvents = () => cardEducationEvents;

export const getCardSet = () => cardSet;

export const getEducationCourses = () => educationCourses;

export const getEducationVideo = () => educationVideo;

export const getIndicatorsCardsInner = () => indicatorsCardsInner;

export const getSubjectCards = () => subjectCards;

export const getServiceBenefits = () => serviceBenefits;

export const getProceduresList = () => proceduresList;

export const getSocialNetworks = () => socialNetworks;

export const getStepsStatic = () => stepsStatic;

export const getPictureBlock = () => pictureBlock;

export const getBarChartPercents = () => barChartsPercents;

export const getBenefitsCards = () => benefitsCards;

export const getNumberedList = () => numberedList;

export const getStepsTabs = () => stepsTabs;

export const getProgram = () => program;

export const getActualCards = () => actualCards;

export const getInterRaoDocPage = () => interRao;

export const getTablePartners = () => tablePartners;

export const getTableQualification = () => tableQualification;

export const getTableCardsProcedure = () => tableCardsProcedure;

export const getTableCriteriasRequirements = () => tableCriteriasRequirements;

export const getTableProcedureUnits = () => tableProcedureUnits;

export const getTablePaymentPlan = () => tablePaymentPlan;

export const getTableProcedureCriteriaIndicators = () => tableProcedureCriteriaIndicators;

export const getVacanciesSpoilers = () => vacanciesSpoilers;

export const getProcedureGeneralInfo = () => procedureGeneralInfo;

export const getLotsRosneft = () => lotsRosneft;

export const getProcedureGeneralInfoWithExtra = () => procedureGeneralInfoWithExtra;

export const getShowMoreText = () => showMoreText;

export const getDocsSection = () => docsSection;

export const getPictureGraph = () => pictureGraph;

export const getList = () => list;

export const getTariffGrid = () => tariffGrid;

export const getServiceTariffSlider = () => serviceTariffSlider;

export const getCardProcedureCatalog = () => cardProcedureCatalog;

export const getProcedureGeneralInfoWithExtra44fz = () => procedureGeneralInfoWithExtra44fz;

// pages

export const getInterRAOPage = () => interRAO;

export const getGovProcurmentPage = () => govProcurement;

export const getRosneftPage = () => rosneft;

// Регионы
export const getRegions = () => regions;

// Торговые секции
export const getTradeSection = () => tradeSection;

// api

/* получение контентной части
    per_page - сколько элементов может быть на странице
    total всего элементов
    to — from 1 to 5, элементы с 1 по 5

    press_center — пресс-центр
    proc_announce — анонсы закупок
    imp_messages — важные сообщения
    media — сми о нас

    type для /events
    курс - course
    семинар — seminar
    вебинар — webinar
*/

export const getContentApi = (name: string, options?: BasicRequestOptions): Promise<AxiosPromise> =>
    contentApiInstance.get(`${name}`, {
        params: options?.params,
        headers: options?.headers
    });

export const getContentApiRoute = (name: string, params?: any): Promise<AxiosPromise> =>
    axios.get(`/api/getContentApi?name=${name}`, { params });

export const getContentPrivateApi = (
    name: string,
    options?: BasicRequestOptions<{
        locale: TLocale;
        is_main?: 1 | 0;
        type?:
            | 'media'
            | 'course'
            | 'press_center'
            | 'expert'
            | 'webinar,seminar,online_conference,conference,supplier_day'
            | 'imp_messages'
            | 'proc_announce';
        audience?: 'customer,not_specified' | 'supplier,not_specified';
        limit?: number;
        dateStartFrom?: string;
        'lectors[]'?: string | string[];
        lector?: string | string[];
        fullDescription?: boolean;
    }>
): Promise<AxiosPromise> =>
    contentApiPrivateInstance.get(`${name}`, {
        params: options?.params,
        headers: options?.headers
    });

export const getSingleNews = (slug: string, options?: BasicRequestOptions): Promise<AxiosPromise<{ news: INews }>> =>
    contentApiPrivateInstance.get(`news/${slug}`, {
        params: options?.params,
        headers: options?.headers
    });

export const getPost = (
    slug: string,
    options?: BasicRequestOptions<{ locale: TLocale }>
): Promise<AxiosPromise<{ post: IEducationPost }>> =>
    contentApiPrivateInstance.get(`posts/${slug}`, {
        params: options?.params,
        headers: options?.headers
    });

export const getSupportArticleSections = (
    members: 'customers' | 'suppliers',
    options?: BasicRequestOptions<{
        locale: TLocale;
        tradingSection?: string;
    }>
): Promise<AxiosPromise<ISupportSections[]>> =>
    contentApiPrivateInstance.get(`/articles/${members}`, {
        params: options?.params,
        headers: options?.headers
    });

export const getTariffSections = (
    options?: BasicRequestOptions<{
        locale: TLocale;
    }>,
    sectionSlug?: string
): Promise<AxiosPromise<ITariffSection[] | ITariffSection>> =>
    contentApiPrivateInstance.get(`/tariffs/${sectionSlug || ''}`, {
        params: options?.params,
        headers: options?.headers
    });

export const getClientReviews = (options?: BasicRequestOptions<{ locale: TLocale }>): Promise<AxiosPromise<IReview[]>> =>
    contentApiPrivateInstance.get('/reviews-list', { params: options?.params, headers: options?.headers });

export const getClientLetters = (
    options?: BasicRequestOptions<{
        locale: TLocale;
        page?: number;
        limit?: number;
    }>
): Promise<AxiosPromise<IClientLettersData>> =>
    contentApiPrivateInstance.get('/letters', { params: options?.params, headers: options?.headers });

// YouTube

export const getYTPlaylist = (id: string, options?: BasicRequestOptions<{ locale: TLocale }>): Promise<AxiosPromise<IYTPlaylist>> =>
    contentApiPrivateInstance.get(`/playlists/${id}`, { params: options?.params, headers: options?.headers });

export const getYTTags = (
    options?: BasicRequestOptions<{ audience: 'customer' | 'supplier'; locale: TLocale }>
): Promise<AxiosPromise<IYTTag[]>> =>
    contentApiPrivateInstance.get(`/playlists/tags`, { params: options?.params, headers: options?.headers });

export const getYTTag = (
    tag: string,
    options?: BasicRequestOptions<{ audience?: 'customer' | 'supplier'; locale?: TLocale }>
): Promise<AxiosPromise<IYTTag>> =>
    contentApiPrivateInstance.get(`/playlists/tags/${tag}`, { params: options?.params, headers: options?.headers });

// Forms

// data содержит следующие поля:
// ip_address = 127.0.0.1
// article_id = 1
// action = like/dislike
export const postSupportArticles = (data: FormData | any, params?: any): Promise<any> => axios.post('/api/postGeneral', data, { params });

export const postContactForm = (data: FormData | any, params?: any): Promise<any> => axios.post(`/api/postContactForm`, data, { params });

// Запросы для регистрации на курс
export const postCourse = (data: FormData | any, params?: any): Promise<any> => axios.post('/api/postGeneral', data, { params });

export const postSubscribeForm = (data: FormData | any, params?: any): Promise<any> =>
    contentApiInstance.post('/subscribe/add_subscribe', data, { params });

//  B24
export const postFormSubscribeBitrix = (data: FormData | any, params?: any): Promise<any> =>
    axios.post('/api/postGeneral', data, { params });

// Процедуры
export const getProcedures = (
    options?: {
        shouldSerialize?: boolean;
    } & BasicRequestOptions
): Promise<AxiosPromise<OrchidPromise<IProcedureShort[]>>> =>
    axiosOpenApiInstance.get(`/procedures`, {
        params: options?.params,
        ...(options?.shouldSerialize ? { paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }) } : {}),
        timeout: API_EVS_TIMEOUT,
        signal: newAbortSignal(API_EVS_TIMEOUT),
        headers: options?.headers
    });

export const getProcedureItem = (
    options?: {
        id?: string | string[];
    } & BasicRequestOptions
): Promise<AxiosPromise<OrchidPromise<IProcedureView>>> =>
    axiosOpenApiInstance.get(`/procedures/${options?.id}`, {
        params: options?.params,
        headers: options?.headers
    });

export const getLots = (
    options?: {
        shouldSerialize?: boolean;
    } & BasicRequestOptions
): Promise<AxiosPromise<OrchidPromise<IProcedureShort[]>>> =>
    axiosOpenApiInstance.get(`/lots`, {
        params: options?.params,
        ...(options?.shouldSerialize ? { paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }) } : {}),
        headers: options?.headers
    });

export const getLotUnits = (
    options?: BasicRequestOptions<{
        id?: string;
        page?: number;
        limit?: number;
        sort?: string;
    }>
): Promise<AxiosPromise<OrchidPromise<ILotUnit[]>>> =>
    axiosOpenApiInstance.get(`/lots/${options?.params?.id}/units`, {
        params: options?.params,
        headers: options?.headers
    });

export const getOurCustomer = (options: {
    slug: string;
    params: { locale: TLocale };
    headers?: AxiosRequestHeaders;
}): Promise<AxiosPromise<IOurCustomerResponseData>> =>
    contentApiPrivateInstance.get(`/customers/${options.slug}`, {
        params: options.params,
        headers: options?.headers
    });

export const getOurCustomerProcedures = (options?: {
    headers?: AxiosRequestHeaders;
}): Promise<AxiosPromise<IOurCustomerProceduresResponseData[]>> =>
    axiosOpenApiInstance.get('/stats/sections-procedures', { headers: options?.headers });

//Листинг организаций
export const getListingOrganizations = (options?: BasicRequestOptions): Promise<AxiosPromise<IOrganizationListing>> =>
    axiosOpenApiInstance.get(`/organizations`, {
        params: options?.params,
        headers: options?.headers,
        timeout: API_EVS_TIMEOUT,
        signal: newAbortSignal(API_EVS_TIMEOUT)
    });

//Данные словарей
export const getDictionary = (
    options: {
        name: string;
    } & BasicRequestOptions
): Promise<AxiosPromise<OrchidPromise<IDictionary[]>>> =>
    axiosOpenApiInstance.get(`/dictionaries/${options.name}`, {
        params: options?.params,
        headers: options?.headers,
        timeout: API_EVS_TIMEOUT,
        signal: newAbortSignal(API_EVS_TIMEOUT)
    });

export const getDictionaryRegions = (options?: BasicRequestOptions): Promise<AxiosPromise<OrchidPromise<IDictionary>>> =>
    axiosOpenApiInstance.get(`/dictionaries/regions`, {
        params: options?.params,
        headers: options?.headers
    });

export const getIndustryCategories = (options?: BasicRequestOptions): Promise<AxiosPromise<OrchidPromise<IIndustryCategories[]>>> => {
    return axiosOpenApiInstance.get(`/dictionaries/industry-categories`, {
        params: options?.params,
        headers: options?.headers
    });
};

//Данные таблиц
export const getQualificationOrgs = (options?: BasicRequestOptions): Promise<AxiosPromise<OrchidPromise<IQualification[]>>> =>
    axiosOpenApiInstance.get(`/qualification/orgs`, {
        params: options?.params,
        headers: options?.headers
    });

export const getQualificationCriterias = (options?: BasicRequestOptions): Promise<AxiosPromise<OrchidPromise<ICriterias[]>>> =>
    axiosOpenApiInstance.get(`/qualification/criterias`, { params: options?.params });

export const getQualificationCriteriasItem = (options: {
    id: number;
    headers?: AxiosRequestHeaders;
}): Promise<AxiosPromise<OrchidPromise<IQualificationRequirements[]>>> =>
    axiosOpenApiInstance.get(`/qualification/criterias/${options.id}`, { headers: options?.headers });

export const getPartners = (options?: BasicRequestOptions): Promise<AxiosPromise<OrchidPromise<IPartners[]>>> =>
    axiosOpenApiInstance.get(`/partners`, {
        params: options?.params,
        headers: options?.headers
    });

export const getOrganizations = (params?: any): Promise<AxiosPromise<OrchidPromise<IPartners[]>>> =>
    axiosOpenApiInstance.get(`/organizations`, { params });

// api news rosneft
export const getRosneftNewsItem = (options?: {
    id?: string | string[];
    headers?: AxiosRequestHeaders;
}): Promise<AxiosPromise<OrchidPromise<INewsCard>>> =>
    axiosOpenApiInstance.get(`/news/${options?.id}`, {
        headers: options?.headers
    });

export const getRosneftNews = (options?: BasicRequestOptions): Promise<AxiosPromise<OrchidPromise<INewsCard[]>>> =>
    axiosOpenApiInstance.get(`/news`, {
        params: options?.params,
        headers: options?.headers
    });

export const getRosneftPartitions = (options: BasicRequestOptions): Promise<AxiosPromise<OrchidPromise<INewsCard[]>>> =>
    axiosOpenApiInstance.get(`/news/partition`, {
        params: options?.params,
        headers: options.headers
    });

export const getRosneftPartitionsItem = (options?: {
    id?: string | string[];
    headers?: AxiosRequestHeaders;
}): Promise<AxiosPromise<OrchidPromise<INewsCard[]>>> =>
    axiosOpenApiInstance.get(`/news/partition/${options?.id}`, { headers: options?.headers });

// api statistics
export const getStatisticsVolumeIncrease = (options: {
    headers: AxiosRequestHeaders;
}): Promise<AxiosPromise<OrchidPromise<IVolumeIncrease>>> =>
    axiosOpenApiInstance.get(`/stats/volume-increase`, {
        headers: options?.headers
    });

// Forms api
export const postFormCallBack = (data: FormData | any, params?: any): Promise<any> =>
    contentApiInstance.post(`/save_form`, data, { params });

// API hh
export const getHhItemVacancy = (id: string | number, locale: string): Promise<AxiosPromise<OrchidPromise<IVacancyHh>>> =>
    axiosApiHh.get(`/vacancies/${id}?locale=${locale}`);

export const getHhItemVacancies = (
    id: string | number = `${process.env.HH_EMPLOYER_ID}`
): Promise<AxiosPromise<OrchidPromise<IVacanciesHh>>> => axiosApiHh.get(`/vacancies?employer_id=${id}`);

// Router Api
export const getProceduresFetch = (params?: any): Promise<AxiosPromise<OrchidPromise<IProcedureShort[]>>> =>
    axios.post(`/api/getProcedures`, { params });

export const getListingOrganizationsFetch = (params?: any): Promise<AxiosPromise<OrchidPromise<IOrganizationListing>>> =>
    axios.post(`/api/getListingOrganizations`, { params });

export const getDictionaryFetch = (name: string, params?: any): Promise<AxiosPromise<OrchidPromise<IDictionary>>> =>
    axios.post(`/api/getDictionary`, { name: name, params: params });

export const getListingQualificationOrgsFetch = (params?: any): Promise<AxiosPromise<OrchidPromise<IQualification[]>>> =>
    axios.post(`/api/getQualificationOrgs`, { params });

export const getListingQualificationCriteriasFetch = (params?: any): Promise<AxiosPromise<OrchidPromise<ICriterias[]>>> =>
    axios.post(`/api/getListingQualificationCriterias`, { params });

export const getListingQualificationCriteriasItem = (params?: any): Promise<AxiosPromise<OrchidPromise<IQualificationRequirements[]>>> =>
    axios.post(`/api/getListingQualificationCriteriasItem`, { params });

export const getPartnersFetch = (params?: any): Promise<AxiosPromise<OrchidPromise<IPartners[]>>> =>
    axios.post(`/api/getPartners`, { params });

// news rosneft
export const getRosneftNewsFetch = (params?: any): Promise<AxiosPromise<OrchidPromise<INewsCard[]>>> =>
    axios.post(`/api/getRosneftNews`, { params });

export const getRosneftPartitionsFetch = (params?: any): Promise<AxiosPromise<OrchidPromise<INewsCard[]>>> =>
    axios.post(`/api/getPartitionsNews`, { params });

export const getLotsFetch = (params?: any, shouldSerialize: boolean = false): Promise<AxiosPromise<OrchidPromise<IProcedureShort[]>>> =>
    axios.post(`/api/getLots`, {
        params,
        ...(shouldSerialize ? { paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'brackets' }) } : {})
    });

export const getLotUnitsFetch = (params?: {
    id?: number;
    page?: number;
    limit?: number;
    sort?: string;
}): Promise<AxiosPromise<OrchidPromise<ILotUnit[]>>> => {
    return axios.post(`/api/getLotUnits`, { params });
};

export const getPropertyTypesFetch = (name: string, params?: any): Promise<AxiosPromise<OrchidPromise<IDictionary[]>>> =>
    axios.post(`/api/getPropertyTypes`, { name: name, params: params });

// получение контентной части
export const getContentFetch = (name: string, params?: any): Promise<AxiosPromise> =>
    axios.post(`/api/getContent`, { name: name, params: params });

export const getHeaderWidgets = (locale: string) => locale && undefined;

export const checkBuild = (): Promise<AxiosPromise> => axios.post('/api/checkBuild');

export const getAllCourses = (options?: BasicRequestOptions<{ locale: TLocale }>): Promise<AxiosPromise<{ items: IEducationEvent[] }>> =>
    contentApiInstance.get('events/all?type=course', {
        params: options?.params,
        headers: options?.headers
    });
// Получение данных для страницы
// pageUrl - url страницы, например rosneft/importozameshchenie
export const getPageData = <PageData>(
    pageUrl: string,
    options?: BasicRequestOptions<{ locale: TLocale }>
): Promise<AxiosPromise<PageData>> =>
    contentApiInstance.get(`/pages/${pageUrl}`, {
        params: options?.params,
        headers: options?.headers
    });

// Получение данных для предварительного просмотра страницы
export const getPagePreview = <PageData>(
    hash: string | string[],
    options?: BasicRequestOptions<{ locale: TLocale }>
): Promise<AxiosPromise<PageData>> =>
    contentApiInstance.get(`/preview/${hash}`, {
        params: options?.params,
        headers: options?.headers
    });

export const getServicePage = <PageData>(
    pageUrl: string,
    params: { locale: TLocale; tradingSection: string; forWhom: string }
): Promise<AxiosPromise<PageData>> => contentApiInstance.get(`/service-pages/${pageUrl}`, { params });
