const variables = {
    col: 100 / 24,
    offset: {
        mobile: 20,
        tablet: 40,
        desktop1280: 53,
        desktop1440: 60,
        desktop1920: 80
    },
    corners: {
        smallUi: '10px',
        smallCard: '14px',
        bigCard: '20px',
        table: '16px'
    },
    videoRatio: (9 / 16) * 100,
    fonts: {
        default: 'Arial',
        NunitoSans: 'NunitoSans, Arial, sans-serif',
        NunitoSansLight: 'NunitoSans-Light, Arial, sans-serif',
        NunitoSansSemiBold: 'NunitoSans-SemiBold, Arial, sans-serif',
        SuisseIntlLight: 'NunitoSans-Light, Arial, sans-serif',
        SuisseIntlBook: 'NunitoSans-SemiBold, Arial, sans-serif'
    },
    offsets: {
        //для обнуления отступа
        none: {
            desktop1920: 0,
            desktop1440: 0,
            desktop1280: 0,
            tablet768: 0,
            mobile375: 0
        },

        //отступ снизу/сверху секций
        main: {
            desktop1280: 60,
            tablet768: 50,
            mobile375: 30
        },

        // 80
        xxxl: {
            desktop1280: 80,
            tablet768: 60,
            mobile375: 40
        },

        // 60
        xxl: {
            desktop1280: 60,
            tablet768: 50,
            mobile375: 30
        },

        // 40
        xl: {
            desktop1280: 40,
            tablet768: 32,
            mobile375: 24
        },

        // 32
        l: {
            desktop1280: 32,
            tablet768: 24,
            mobile375: 20
        },

        // 24
        m: {
            desktop1280: 24,
            tablet768: 24,
            mobile375: 20
        },

        // 20
        s: {
            desktop1280: 20,
            tablet768: 20,
            mobile375: 16
        },

        // 16
        xs: {
            desktop1280: 16,
            tablet768: 16,
            mobile375: 12
        },

        // 12
        xxs: {
            desktop1280: 12,
            tablet768: 12,
            mobile375: 12
        },

        // 8
        xxxs: {
            desktop1280: 8,
            tablet768: 8,
            mobile375: 8
        },

        // 4
        xxxxs: {
            desktop1280: 4,
            tablet768: 4,
            mobile375: 4
        }
    }
};

export type FontFamily = keyof typeof variables.fonts;

export type Offsets = keyof typeof variables.offsets;

export default variables;
