import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { detect } from 'detect-browser';
import Header, { HeaderProps } from '../Header/Header';
import Footer from '../Footer/Footer';
import { BasePageProps } from '@/interfaces';
import useIsIE11 from '@/hooks/useIsIE11';
import ResizeObserver from 'resize-observer-polyfill';
import { SidebarProps } from '@/components/common/Sidebar/Sidebar';
import { LeadImgProps } from '@/components/blocks/LeadImg/LeadImg';
import breakpoints from '@/style/breakpoints';

import { DefaultTheme, ThemeProvider } from 'styled-components';
import { baseTheme } from '@/style/themes';

import ScrollTopButton from '../ScrollTopButton/ScrollTopButton';
import useImportantMessageHeight from '@/hooks/useImportantMessageHeight';
import { WebimLoadingBtn } from '../WebimLoadingBtn/WebimLoadingBtn';
import { Container } from '@/components/common/Layout/Layout.styled';
import ImportantMessage from '@/components/blocks/ImportantMessage/ImportantMessage';
import debounce from 'lodash.debounce';
import { useWindowEvent } from '@/hooks/useWindowEvent';

const Sandwich = dynamic(() => import('../Sandwich/Sandwich'));

interface LayoutProps extends BasePageProps {
    theme?: DefaultTheme;
    sidebar?: SidebarProps;
    leadImg?: LeadImgProps;
    isFirstBgFill?: HeaderProps['isFirstBgFill'];
    isDarkHeader?: HeaderProps['isDarkHeader'];
    propsFooterForm?: string;
    modalTitle?: string;
    errorPage?: boolean;
    sidebarPath?: string;
    modalID?: string;
    noFhdSides?: boolean;
    isInner?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
    children,
    meta,
    theme = baseTheme,
    isDarkHeader = false,
    isInner = true,
    propsFooterForm,
    modalTitle,
    modalID
}) => {
    const isIE11 = useIsIE11();
    const [mountIE, setIEMounted] = useState(true);
    const [isTablet, setIsTablet] = useState(false);
    const layOutOffset = useImportantMessageHeight();

    useEffect(() => {
        if (detect()?.os === 'iOS' || detect()?.os === 'Mac OS') document.documentElement.classList.add('_is-ios');

        const resizeHandler = () => {
            if (!window) return;
            const { innerWidth } = window;

            setIsTablet(innerWidth < breakpoints['xl']);
        };

        const resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver?.observe(document.documentElement);
        resizeHandler();

        return () => resizeObserver?.disconnect();
    }, []);

    const debouncedResizeHandler = debounce(() => {
        const h = `${window.innerHeight * 0.01}px`;
        document.documentElement.style.setProperty('--vh', h);
    }, 100);
    useWindowEvent('resize', debouncedResizeHandler);

    useEffect(() => {
        let t: NodeJS.Timeout;
        if (isIE11) {
            setIEMounted(false);

            t = setTimeout(() => {
                document.documentElement.classList.add('_is-ie');
                setIEMounted(true);
            }, 10);
        }
        return () => clearTimeout(t);
    }, [isIE11]);

    return (
        <ThemeProvider theme={theme}>
            <Head>
                <title>{meta.title}</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="description" content={meta.description} />
                <meta name="keywords" content={meta.keywords} />
                <meta name="og:title" content={meta.title} />
                <meta name="og:description" content={meta.description} />
                {meta.image && <meta property="og:image" content={meta.image} />}
                {meta.noindex && <meta name="robots" content="noindex" />}
                {meta.canonical && <link rel="canonical" href={meta.canonical} />}
            </Head>

            {mountIE && (
                <>
                    <ImportantMessage />

                    <Header isTablet={isTablet} isDarkHeader={isDarkHeader} />

                    {isTablet && <Sandwich layOutOffset={layOutOffset} />}

                    {!isTablet && <ScrollTopButton />}

                    {!(typeof window !== 'undefined' && !!window.MSInputMethodContext && !!document.DOCUMENT_NODE) && <WebimLoadingBtn />}

                    <Container style={{ paddingTop: `${layOutOffset}px` }} isInner={isInner}>
                        {children}
                    </Container>
                    <Footer propsFooterForm={propsFooterForm} modalTitle={modalTitle} modalID={modalID} />
                </>
            )}
        </ThemeProvider>
    );
};

export default Layout;
